<template>
  <div class="applylist-style">
    <titles :title="$t('text.t464')" />
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
      >
        <div class="item between-center" v-for="item in list" :key="item.id">
          <div class="left">
            <div class="t1">{{item.flag==0?$t('text.t444'):item.flag==1?$t('text.t445'):''}}</div>
            <div class="t2 start-center">
              <img :src="chain_list.find(ele=>ele.chain_type == item.pay_type).chain_logo" alt="" class="t2img">
              <div class="t3">{{chain_list.find(ele=>ele.chain_type == item.pay_type).name}}</div>
            </div>
          </div>
          <div class="right">
            <div class="t1" v-if="item.status == 0">{{$t('text.t465')}}</div>
            <div class="t1" v-if="item.status == 1">
              <span v-if="item.hash_status == 0" @click="buy(item)">{{$t('text.t466')}}>></span>
              <span v-if="item.hash_status == 1">{{$t('text.t467')}}</span>
              <span v-if="item.hash_status == 2">{{$t('text.t468')}}</span>
              <span v-if="item.hash_status == 3">{{$t('text.t469')}}</span>
            </div>
            <div class="t1" v-if="item.status == 2">{{$t('text.t470')}}</div>
            <div class="t3" v-if="item.status == 1">{{$t('text.t471')}}:{{item.get}} USDT</div>
            <div class="t3" v-if="item.status == 2">{{$t('text.t472')}}:{{item.memo}}</div>
            <div class="t2">{{$parseTime(item.createtime)}}</div>
          </div>
        </div>
      </van-list>
    </div>
    <div class="loading center-center" v-if="isloading">
      <van-loading size="28px" color="#ffffff" vertical></van-loading>
    </div>
  </div>
</template>

<script>
import titles from "@/components/titles/index.vue";
import { mapState } from "vuex";
export default {
  components:{
    titles
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      isloading:false,
      toastmsg:''
    };
  },
  computed: {
    ...mapState(["language","config","chainId","chain_list"]),
  },
  mounted() {},
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        language:this.language
      };
      this.$http.get("/casting/creatorList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    async buy(data){
      const ids = this.chain_list.find(
        (ele) => ele.chain_id == this.chainId
      ).chain_type;
      if(ids != data.pay_type){
        this.$toast(this.$t('text.t473'))
        return
      }
      try {
        const web3 = new this.Web3(ethereum)
        this.isloading = true
        const {orderno,get,address} = data
        const u_contract = this.chainId == "0x2d20"
          ? this.config.omp_usdt_contract
          : this.chainId == "0x38"
          ? this.config.bsc_usdt_contract
          : this.chainId == "0x1"
          ? this.config.eth_usdt
          : this.chainId == "0x89"
          ? this.config.pol_usdt
          : "";
        const u_abi = this.chainId == '0x2d20' ? JSON.parse(this.config.omp_usdt_abi) :  JSON.parse(this.config.bsc_usdt_abi);
        const contract = new web3.eth.Contract(u_abi,u_contract,{from:address})
        console.log(contract);
        
        contract.methods.transfer('0xDAFE4217f53B833b6EED2Cc7a0b8Fc6913617c6b',web3.utils.toWei(get,this.chainId == "0x89" || this.chainId == "0x1"?'mwei':'ether')).send()
        .on('transactionHash', async (hash)=>{
          const {msg} = await this.$http.post('/casting/creatorHash',{hash,orderno,language:this.language})
          this.toastmsg = msg
        })
        .on('receipt', (receipt)=>{
          console.log(receipt);
          this.isloading = false
          this.$toast(this.toastmsg)
          //
          this.page = 1
          this.loading = false
          this.finished = false
          this.list = []
        })
        .on('error', (error)=>{
          this.isloading = false
          console.log(error);
        });
      } catch (error) {
        this.isloading = false
        console.log(error);
      }
    }
  },
}
</script>

<style lang="less">
.applylist-style{
  .loading{
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .list{
    .item{
      padding: 20px 15px;
      border-bottom: 1px solid #E1E1E1;
      .left{
        padding-right: 10px;
        .t1{
          font-size: 15px;
          font-weight: bold;
          color: #323232;
          margin-bottom: 12px;
        }
        .t2{
          .t2img{
            width: 15px;
            height: 15px;
            margin-right: 5px;
          }
          .t3{
            font-size: 12px;
            font-weight: 500;
            color: #323232;
          }
        }
      }
      .right{
        white-space: nowrap;
        flex: 1;
        text-align: right;
        .t1{
          font-size: 15px;
          font-weight: bold;
          color: #323232;
          margin-bottom: 10px;
        }
        .t2{
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
        .t3{
          font-size: 12px;
          font-weight: 500;
          color: #323232;
          margin-bottom: 10px;
          white-space:pre-wrap;
        }
      }
    }
  }
}
</style>